<template>
    <div v-if="!freeze" class="preloader" :class="verticalCenter ? 'preloader--vertical-center' : ''" :style="'font-size:'+fontSize+'px'">
        <i class="fas fa-circle-notch fa-spin"></i>
    </div>
    <div v-else class="preloader--back">
        <div class="preloader" :class="verticalCenter ? 'preloader--vertical-center' : ''" :style="'font-size:'+fontSize+'px'">
            <i class="fas fa-circle-notch fa-spin"></i>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PreloaderComponent",
        props:{
            fontSize: {
                type: String,
                required: false,
                default: "20"
            },
            verticalCenter: {
                type: Boolean,
                required: false,
                default: false
            },
            freeze: {
                type: Boolean,
                required: false,
                default: false
            },
        }
    }
</script>