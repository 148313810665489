import Request from "../plugins/Request";
import qs from "qs";

export default {
    namespaced: true,
    state: {
        list: [],
        count: 0,
        pages: 0,
        current: 0,
        loading: false, // use for pre loaders
    },
    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },
        SET_COUNT(state, count) {
            state.count = count;
        },
        SET_PAGES(state, pages) {
            state.pages = pages;
        },
        SET_CURRENT(state, current) {
            state.current = current;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
    },
    actions: {
        list({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get('/surfaces', {
                    params: {
                        limit:    payload.limit || 20,
                        offset:   payload.offset || 0,
                        order_by: payload.orderBy || 'id DESC',
                        filter:   payload.filter || {},
                        query:    payload.query || {},
                    },
                    paramsSerializer: function(params) {
                        return qs.stringify(params, {arrayFormat: 'repeat'});
                    },
                })
                    .then(resp => {

                        // set state
                        commit('SET_LIST', resp.data.list);
                        commit('SET_COUNT', resp.data.count);
                        commit('SET_CURRENT', resp.data.current);
                        commit('SET_PAGES', resp.data.pages);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state
                        commit('SET_LIST', []);
                        commit('SET_COUNT', 0);
                        commit('SET_CURRENT', 0);
                        commit('SET_PAGES', 0);

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store surface->list: server error");

                        reject(err);
                    })
            })
        },
        map({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get('/surface/map', {
                    params: {
                        lat1:     payload.lat1,
                        lng1:     payload.lng1,
                        lat2:     payload.lat2,
                        lng2:     payload.lng2,
                        zoom:     payload.zoom,
                        maxZoom:  payload.maxZoom,
                        filter:   payload.filter || {},
                        query:    payload.query || {},
                    },
                    paramsSerializer: function(params) {
                        return qs.stringify(params, {arrayFormat: 'repeat'});
                    },
                })
                    .then(resp => {
                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store surface->map: server error");

                        reject(err);
                    })
            })
        },
        mapList({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('hash'))
                {
                    // show error
                    const errorMsg = 'Hash обязательный параметр.';
                    showError(`Store surface->mapList: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // request
                Request.get('/surface/map-list', {
                    params: {
                        limit:    payload.limit || 20,
                        offset:   payload.offset || 0,
                        order_by: payload.orderBy || 'id DESC',
                        hashList: payload.hash,
                        filter:   payload.filter || {},
                        query:    payload.query || {},
                    },
                    paramsSerializer: function(params) {
                        return qs.stringify(params, {arrayFormat: 'repeat'});
                    },
                })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        // show error
                        showError("Store surface->mapList: server error");

                        reject(err);
                    })
            })
        },
        autocomplete({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('query') || !payload.hasOwnProperty('field'))
                {
                    showError("Store surface->autocomplete: Не правильные параметры.");
                    return reject();
                }

                if(payload.query.length === 0)
                {
                    return resolve({
                        data: []
                    });
                }

                // request
                Request.get('/surface/autocomplete', {
                    params: {
                        query: payload.query,
                        field: payload.field,
                    }
                })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        // show error
                        showError("Store surface->autocomplete: server error");
                        reject(err);
                    })
            })
        },
        status({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('surfaceId') || !payload.hasOwnProperty('status'))
                {
                    showError("Store surface->status: Не правильные параметры.");
                    return reject();
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.put('/surfaces/' + payload.surfaceId, {
                    status:  payload.status,
                })
                    .then(resp => {
                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store surface->status: server error");

                        reject(err);
                    })
            })
        },
        // delete: ({commit}, surface) => {
        //     // return promise
        //     return new Promise((resolve, reject) => {
        //
        //         // check params
        //         if(!surface.hasOwnProperty('surfaceId'))
        //         {
        //             // show error
        //             const errorMsg = 'Невозможно удалить поверхность без ID.';
        //             showError(`Store surface->delete: ${errorMsg}`);
        //             return reject(errorMsg);
        //         }
        //
        //         // start pre loader
        //         commit('SET_LOADING', true);
        //
        //         // request
        //         Request.delete('/surfaces/' + surface.surfaceId)
        //             .then(resp => {
        //                 // stop pre loader
        //                 commit('SET_LOADING', false);
        //                 resolve(resp);
        //             })
        //             .catch(err => {
        //                 // stop pre loader
        //                 commit('SET_LOADING', false);
        //                 reject(err);
        //             });
        //     });
        // },
    },
    getters: {
    }
};
