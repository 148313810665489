import store from './store'
import './global_components'

/**
 * load ME request
 */
let loadMeStarted  = false;
let loadMeInterval = null;

const AccountMeRequest = () => {
    store.dispatch('account/me')
        .then(() => {
            qw('Me loaded.');
        })
        .catch(err => {
            console.error('Me error loading.' + err);
        });
};

const StartAccountMe = () => {
    if(loadMeStarted)
        return;

    qw('StartAccountMe');

    loadMeStarted = true;

    loadMeInterval = setInterval(() => {
        qw('loadMe');
        if(!store.getters['account/isLoggedIn'])
            qw('not isLoggedIn');
        else
            AccountMeRequest();

    }, 60*1000);
};

const StopAccountMe = () => {
    qw('StopAccountMe');

    loadMeStarted = false;
    clearInterval(loadMeInterval);
};


/**
 * Hooks
 */
const OnAfterLogout = () => {
    qw('OnAfterLogout');

    StopAccountMe();
};

const OnAfterLogin = () => {
    qw('OnAfterLogin');

    // wait store
    setTimeout(()=>{
        StartAccountMe();
    }, 200);
};

export default {
    AccountMeRequest: AccountMeRequest,
    OnAfterLogin: OnAfterLogin,
    OnAfterLogout: OnAfterLogout,
};