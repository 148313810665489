<template>
    <div id="app" class="page-padding-top" v-if="loaded">
        <navigation-component :items="navBarMenu"></navigation-component>
        <transition>
            <router-view></router-view>
        </transition>
    </div>
    <preloader fontSize="40" :freeze="true" :verticalCenter="true" v-else/>
</template>
<script>
    import NavigationComponent from '../components/navigation/NavigationComponent'
    import Hooks from '../hooks'

    export default {
        name: 'MainLayout',
        components: {
            NavigationComponent
        },
        data () {
            return {
                loaded: false
            }
        },
        computed: {
            navBarMenu() {
                return [
                    {
                        url: { name: "campaigns" },
                        title: 'Адресные программы',
                        hidden: this.$can(this.currentUser, 'section_campaigns') !== true,
                    },
                    {
                        url: { name: "surfaces" },
                        title: 'Поверхности',
                        hidden: this.$can(this.currentUser, 'section_surfaces') !== true,
                    },
                    {
                        url: { name: "reports" },
                        title: 'Отчёты',
                        hidden: this.$can(this.currentUser, 'section_reports') !== true,
                    },
                    {
                        url: { name: "users" },
                        title: 'Пользователи',
                        hidden: this.$can(this.currentUser, 'section_users') !== true,
                    },
                ];
            },
            currentUser() {
                return this.$store.state.account.user;
            }
        },
        created() {
            qw('MainLayout hook created.');
            if(this.$store.getters['account/isLoggedIn'])
            {
                const accountMe  = this.$store.dispatch('account/me');
                const dictionary = this.$store.dispatch('dictionary/index');

                accountMe.then(() => {
                    dictionary.then(() => {
                        qw('MainLayout created success.');

                        this.loaded = true;
                        Hooks.OnAfterLogin();

                    }).catch((err) => {
                        console.error('MainLayout created error request /dictionary/index. ' + err);
                        this.$router.push({ name: 'login' });
                    });
                }).catch((err) => {
                    console.error('MainLayout created error request /account/me. ' + err);
                    this.$router.push({ name: 'login' });
                });
            }
            else
            {
                qw('MainLayout created. User not login.');
                this.$router.push({ name: 'login' });
            }
        }
    }
</script>