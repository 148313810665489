<template>
    <div class="wr-modal" v-show="isOpen">
        <div class="wr-modal__dialog">
            <div class="wr-modal__content">
                <div class="wr-modal__header" v-if="hasTitleSlot || title">
                    <div class="wr-modal__title">
                        {{ title }}
                        <slot name="title"></slot>
                    </div>
                </div>
                <button class="wr-modal__close" v-if="closeButton" :aria-label="closeButtonTitle" @click.prevent="close"><span aria-hidden="true">&times;</span></button>

                <div class="wr-modal__body" v-if="hasBodySlot">
                    <slot name="body"></slot>
                </div>
                <div class="wr-modal__footer" v-if="hasFooterSlot">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name:"WrModalComponent",
        props: {
            backdrop: {
                type: Boolean,
                required: false,
                default: true
            },
            closeButton: {
                type: Boolean,
                required: false,
                default: true
            },
            closeESC: {
                type: Boolean,
                required: false,
                default: true
            },
            closeButtonTitle: {
                type: String,
                required: false,
                default: "Close"
            },
            modalClass: {
                type: String,
                required: false,
                default: ""
            },
            title: {
                type: String,
                required: false,
                default: ""
            }
        },
        data() {
            return {
                isOpen: false,
            }
        },
        computed: {
            hasTitleSlot () {
                return !!this.$slots.title
            },
            hasFooterSlot () {
                return !!this.$slots.footer
            },
            hasBodySlot () {
                return !!this.$slots.body
            }
        },
        methods: {
            handleKeyupDoc(e) {
                if(e.keyCode === 27)
                    this.close();
            },
            handleClickDoc(e) {
                if(e.target === this.$el)
                    if(this.backdrop)
                        this.toggle(false);
                    else
                    {
                        this.$el.classList.add('wr-modal--bounce');
                        setTimeout(() => {
                            this.$el.classList.remove('wr-modal--bounce');
                        }, 300)
                    }
            },
            open() {
                this.toggle(true);
            },
            close() {
                this.toggle(false);
            },
            toggle(state) {
                this.isOpen = state;

                let body = document.querySelector('body');
                if(this.isOpen)
                {
                    // add class to body
                    body.classList.add('wr-modal__modal-open');

                    // add custom class
                    if(this.modalClass !== "")
                        this.$el.classList.add(this.modalClass);

                    // add open class
                    this.$el.classList.add('wr-modal--is-visible');
                    this.$el.classList.add('wr-modal--fade');

                    // backdrop click
                    document.addEventListener('click', this.handleClickDoc);

                    // close ESC
                    if(this.closeESC)
                        document.addEventListener('keyup', this.handleKeyupDoc);

                    // scroll to top
                    setTimeout(() => this.$el.scrollTop = 0, 30);

                    // event
                    this.$emit('onOpen');
                }
                else
                {
                    // remove class to body
                    const modals = document.querySelectorAll('.wr-modal--is-visible');
                    if(modals.length === 1)
                        body.classList.remove('wr-modal__modal-open');

                    // remove custom class
                    if(this.modalClass !== "")
                        this.$el.classList.remove(this.modalClass);

                    // remove open class
                    this.$el.classList.remove('wr-modal--is-visible');
                    this.$el.classList.remove('wr-modal--fade');

                    // close ESC
                    if(this.closeESC)
                        document.removeEventListener('keyup', this.handleKeyupDoc);

                    // backdrop click
                    document.removeEventListener('click', this.handleClickDoc);

                    // event
                    this.$emit('onClose');
                }
            }
        },
        beforeDestroy() {
            // ESC keyup remove
            document.removeEventListener('keyup', this.handleKeyupDoc);

            // backdrop click remove
            document.removeEventListener('click', this.handleClickDoc);
        }
    }
</script>