<template>
    <div class="dropdown">
        <div class="dropdown__title" @click="toggle">
            <div>{{ selected_item[propTitle] }}</div>
            <i class="fas dropdown__arrow" :class="(show) ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
        </div>
        <div class="dropdown__items" v-show="show">
            <div class="dropdown__item" v-for="item in items" :key="item[propId]">
                <a href="#" :class="(selected_item[propId] === item[propId]) ? 'active' : ''" @click="select($event, item)">{{ item[propTitle] }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DropdownComponent",
        props:{
            selected: {
                type: Number,
                required: false,
                default: ''
            },
            propTitle: {
                type: String,
                required: false,
                default: 'title'
            },
            propId: {
                type: String,
                required: false,
                default: 'id'
            },
            items: {
                type: Array,
                required: true,
                default: ()=> {
                    return [];
                }
            },
        },
        data(){
            return {
                show: false
            };
        },
        computed: {
            selectedItem() {
                return this.selected;
            },
            selected_item() {
                const selectedAr = this.items.filter(item => item[this.propId] === this.selectedItem);
                if(selectedAr.length === 0)
                    return {};

                return selectedAr[0];
            }
        },
        methods: {
            handler(e){
                this.show = false;
                document.removeEventListener('click', this.handler);
            },
            toggle(e) {
                e.preventDefault();
                this.show = !this.show;

                const self = this;
                if(this.show)
                    setTimeout(function () {
                        document.addEventListener('click', self.handler, false);
                    }, 100);
                else
                    document.removeEventListener('click', this.handler);

            },
            select(e, item) {
                e.preventDefault();

                if(this.selectedItem === item[this.propId])
                    return;

                this.$emit('selected', item);
                this.toggle(e);
            }
        }
    }
</script>