<template>
    <div>
        <transition>
            <router-view></router-view>
        </transition>
    </div>
</template>
<script>
    export default {
        name: 'LoginLayout',
    }
</script>