import Vue from 'vue'
import axios from 'axios'
import router from '../routes'
import store from '../store'
import Config from '../vue.config'

Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.baseURL = Config.apiUrl;
Vue.prototype.$http.defaults.withCredentials = true;
Vue.prototype.$http.defaults.responseType = 'json';

// restore auth
const token = localStorage.getItem('token');
if (token)
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;

// Add a response interceptor
Vue.prototype.$http.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    switch (error.response.status) {
        case 403:
            router.push({name:"403"});
            break;
        case 401:
            // if user not auth
            if(store.state.account.token === '')
            {
                router.push({name:"login"});
                return Promise.reject(error);
            }

            // if user auth need logout
            store.dispatch('account/logout')
                .then(() => {
                    router.push({name:"login"})
                }).catch(err => {
                    router.push({name:"login"})
                });
            break;
    }

    if(error.response.status !== 200 && error.response.status !== 422)
        console.error(error.response);
        // showError(error.response.data.message, 'Error ' + error.response.status);

    return Promise.reject(error);
});

export default Vue.prototype.$http;