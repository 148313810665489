import Request from "../plugins/Request";
import qs from "qs";

export default {
    namespaced: true,
    state: {
        list: [],
        count: 0,
        pages: 0,
        current: 0,
        loading: false, // use for pre loaders
    },
    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },
        SET_COUNT(state, count) {
            state.count = count;
        },
        SET_PAGES(state, pages) {
            state.pages = pages;
        },
        SET_CURRENT(state, current) {
            state.current = current;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
    },
    actions: {
        list({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get('/city', {
                    params: {
                        limit:    payload.limit || 20,
                        offset:   payload.offset || 0,
                        order_by: payload.orderBy || 'id DESC',
                        filter:   payload.filter || {},
                        query:    payload.query || {},
                    },
                    paramsSerializer: function(params) {
                        return qs.stringify(params, {arrayFormat: 'repeat'});
                    },
                })
                    .then(resp => {

                        // set state
                        commit('SET_LIST', resp.data.list);
                        commit('SET_COUNT', resp.data.count);
                        commit('SET_CURRENT', resp.data.current);
                        commit('SET_PAGES', resp.data.pages);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state
                        commit('SET_LIST', []);
                        commit('SET_COUNT', 0);
                        commit('SET_CURRENT', 0);
                        commit('SET_PAGES', 0);

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store city->list: server error");

                        reject(err);
                    })
            })
        },
        autocomplete({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('query') || !payload.hasOwnProperty('field'))
                {
                    showError("Store city->autocomplete: Не правильные параметры.");
                    return reject();
                }

                if(payload.query.length === 0)
                {
                    return resolve({
                        data: []
                    });
                }

                // request
                Request.get('/city/autocomplete', {
                    params: {
                        query: payload.query,
                        field: payload.field,
                    }
                })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        // show error
                        showError("Store city->autocomplete: server error");
                        reject(err);
                    })
            })
        },
    },
    getters: {
    }
};
