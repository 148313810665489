import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

// modules
import account from "./account"
import user from "./user"
import dictionary from "./dictionary"
import surface from "./surface"
import campaign from "./campaign"
import campaignSurface from "./campaignSurface"
import report from "./report"
import city from "./city"

const store = new Vuex.Store({
    strict: true,
    modules: {
        account: account,
        user: user,
        dictionary: dictionary,
        surface: surface,
        campaign: campaign,
        campaignSurface: campaignSurface,
        report: report,
        city: city,
    }
});
export default store