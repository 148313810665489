import Request from "../plugins/Request";
import qs from "qs";

export default {
    namespaced: true,
    state: {
        editCampaign: {},
        rights: {},
        list: [],
        count: 0,
        pages: 0,
        current: 0,
        campaignReportList: [],
        loading: false, // use for pre loaders
        loadingCsv: false, // use for pre loaders
    },
    mutations: {
        SET_EDIT_CAMPAIGN(state, campaign) {
            state.editCampaign = campaign;
        },
        SET_LIST(state, list) {
            state.list = list;
        },
        SET_COUNT(state, count) {
            state.count = count;
        },
        SET_PAGES(state, pages) {
            state.pages = pages;
        },
        SET_CURRENT(state, current) {
            state.current = current;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_RIGHTS(state, rights) {
            state.rights = rights;
        },
        SET_CAMPAIGN_REPORT_LIST(state, list) {
            state.campaignReportList = list;
        }
    },
    actions: {
        list({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get('/campaigns', {
                    params: {
                        limit:    payload.limit || 20,
                        offset:   payload.offset || 0,
                        order_by: payload.orderBy || 'id DESC',
                        filter:   payload.filter || {},
                        query:    payload.query || {},
                    },
                    paramsSerializer: function(params) {
                        return qs.stringify(params, {arrayFormat: 'repeat'});
                    },
                })
                    .then(resp => {

                        // set state
                        commit('SET_LIST', resp.data.list);
                        commit('SET_COUNT', resp.data.count);
                        commit('SET_CURRENT', resp.data.current);
                        commit('SET_PAGES', resp.data.pages);
                        commit('SET_RIGHTS', resp.data.rights);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state
                        commit('SET_LIST', []);
                        commit('SET_COUNT', 0);
                        commit('SET_CURRENT', 0);
                        commit('SET_PAGES', 0);
                        commit('SET_RIGHTS', {});

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store campaigns->list: server error");

                        reject(err);
                    })
            })
        },
        view({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                // check params
                if(!payload.hasOwnProperty('campaignId'))
                {
                    // show error
                    const errorMsg = 'Не передан campaignId.';
                    showError(`Store campaign->view: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get('/campaigns/' + payload.campaignId)
                    .then(resp => {

                        // check data
                        if(!resp.data.hasOwnProperty('id'))
                        {
                            // show error
                            const errorMsg = 'Адресная программа с таким ID не найдена.';
                            showError(`Store campaign->view: ${errorMsg}`);
                            return reject(errorMsg);
                        }

                        // set state
                        commit('SET_EDIT_CAMPAIGN', resp.data);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);

                    })
                    .catch(err => {
                        // reset state
                        commit('SET_EDIT_CAMPAIGN', {});

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store campaign->view: server error");

                        reject(err);
                    })
            })
        },
        create({commit}, campaign) {
            // return promise
            return new Promise((resolve, reject) => {

                // check params
                if(campaign.hasOwnProperty('id'))
                {
                    // show error
                    const errorMsg = 'Нельзя создавать адресные программы с ID.';
                    showError(`Store campaign->create: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.post('/campaigns', campaign)
                    .then(resp => {

                        // check data
                        if(!resp.data.hasOwnProperty('campaign') || !resp.data.campaign.hasOwnProperty('id'))
                        {
                            // show error
                            const errorMsg = 'Не удалось создать адресную программу.';
                            showError(`Store campaign->create: ${errorMsg}`);
                            return reject(errorMsg);
                        }

                        // set state
                        commit('SET_EDIT_CAMPAIGN', resp.data.campaign);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);

                    })
                    .catch(err => {
                        // reset state
                        commit('SET_EDIT_CAMPAIGN', {});

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        if (err.response.status !== 422)
                            showError("Store campaign->create: server error");

                        reject(err);
                    })
            })
        },
        update({commit}, campaign) {
            // return promise
            return new Promise((resolve, reject) => {

                // check params
                if(!campaign.hasOwnProperty('id'))
                {
                    // show error
                    const errorMsg = 'Невозможно обновить адресную программу без ID.';
                    showError(`Store campaign->update: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.put('/campaigns/' + campaign.id, campaign)
                    .then(resp => {

                        // check data
                        if(!resp.data.hasOwnProperty('campaign') || !resp.data.campaign.hasOwnProperty('id'))
                        {
                            // show error
                            const errorMsg = 'Не удалось обновить адресную программу.';
                            showError(`Store user->update: ${errorMsg}`);
                            return reject(errorMsg);
                        }

                        // set state
                        commit('SET_EDIT_CAMPAIGN', resp.data.campaign);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);

                    })
                    .catch(err => {
                        // reset state
                        commit('SET_EDIT_CAMPAIGN', {});

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        if (err.status !== 422)
                            showError("Store campaign->update: server error");

                        reject(err);
                    })
            })
        },
        autocomplete({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('query') || !payload.hasOwnProperty('field'))
                {
                    showError("Store campaigns->autocomplete: Не правильные параметры.");
                    return reject();
                }

                if(payload.query.length === 0)
                {
                    return resolve({
                        data: []
                    });
                }

                // request
                Request.get('/campaign/autocomplete', {
                    params: {
                        query: payload.query,
                        field: payload.field,
                    }
                })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        // show error
                        showError("Store campaigns->autocomplete: server error");
                        reject(err);
                    })
            })
        },
        reportList({commit}) {
            // return promise
            return new Promise((resolve, reject) => {

                // request
                Request.get('/campaign/report-list')
                    .then(resp => {
                        commit('SET_CAMPAIGN_REPORT_LIST', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        // show error
                        showError("Store campaigns->reportList: server error");
                        reject(err);
                    })
            })
        },
        currentReport({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                // check params
                if(!payload.hasOwnProperty('campaignId'))
                {
                    // show error
                    const errorMsg = 'Не передан campaignId.';
                    showError(`Store campaign->currentReport: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // request
                Request.get('/campaigns/' + payload.campaignId + "/current-report")
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        // show error
                        showError("Store campaign->currentReport: server error");
                        reject(err);
                    })
            })
        },
        status({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('campaignId') || !payload.hasOwnProperty('status'))
                {
                    showError("Store campaigns->status: Не правильные параметры.");
                    return reject();
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.put('/campaigns/' + payload.campaignId, {
                    status:  payload.status,
                })
                    .then(resp => {
                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store campaigns->status: server error");

                        reject(err);
                    })
            })
        },
        delete: ({commit}, campaign) => {
            // return promise
            return new Promise((resolve, reject) => {

                // check params
                if(!campaign.hasOwnProperty('campaignId'))
                {
                    // show error
                    const errorMsg = 'Невозможно удалить адресную программу без ID.';
                    showError(`Store surface->delete: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.delete('/campaigns/' + campaign.campaignId)
                    .then(resp => {
                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // stop pre loader
                        commit('SET_LOADING', false);
                        reject(err);
                    });
            });
        },
    },
    getters: {
    }
};
