<template>
    <div class="custom-pagination" v-if="pageNumbers.length > 1 && paginationType === paginationTypeList.REMOTE">
        <div class="custom-pagination__item" v-for="page in pageNumbers">
            <span class="btn custom-pagination__item-obj" v-if="'...' === page">{{ page }}</span>
            <router-link
                    class="btn custom-pagination__item-obj"
                    :to="link(page)"
                    v-if="currentPage !== page && '...' !== page">{{ page }}</router-link>
            <span class="btn custom-pagination__item-obj custom-pagination__item-active" v-if="currentPage === page && '...' !== page">{{ page }}</span>
        </div>
    </div>
    <div class="custom-pagination" v-else-if="pageNumbers.length > 1 && paginationType === paginationTypeList.LOCAL">
        <div class="custom-pagination__item" v-for="page in pageNumbers">
            <span class="btn custom-pagination__item-obj" v-if="'...' === page">{{ page }}</span>
            <a
                    :href="`#page_${page}`"
                    class="btn custom-pagination__item-obj"
                    @click="localClick($event, page)"
                    v-if="currentPage !== page && '...' !== page">{{ page }}</a>
            <span class="btn custom-pagination__item-obj custom-pagination__item-active" v-if="currentPage === page && '...' !== page">{{ page }}</span>
        </div>
    </div>
</template>

<script>
    import { PaginationTypes } from './CustomPaginationConstants'

    export default {
        name: "CustomPaginationComponent",
        props: {
            paginationType: {
                type: Number,
                default: PaginationTypes.REMOTE
            },
            routerNamePageSort: {
                type: String,
            },
            routerNamePage: {
                type: String,
            },
            routerNameFirstPageSort: {
                type: String,
            },
            routerNameFirstPage: {
                type: String,
            },
            currentPage: {
                type: Number,
            },
            currentSort: {
                type: String,
            },
            pages: {
                type: Number,
            },
            pageChanged: {
                type: Function,
            },
            perPageChanged: {
                type: Function,
            },
            pageRange: {
                type: Number,
                default: 3
            },
            marginPages: {
                type: Number,
                default: 1
            },
        },
        data(){
            return {
                paginationTypeList: PaginationTypes
            }
        },
        computed: {
            pageNumbers: function () {
                let numbers = [];

                if (this.pages <= this.pageRange) {
                    for (let index = 0; index < this.pages; index++) {
                        numbers.push(index+1)
                    }
                } else {
                    const halfPageRange = Math.floor(this.pageRange / 2);
                    let setPageItem = index => {
                        numbers.push(index+1);
                    };
                    let setBreakView = index => {
                        numbers.push('...');
                    };
                    // 1st - loop thru low end of margin pages
                    for (let i = 0; i < this.marginPages; i++) {
                        setPageItem(i);
                    }
                    // 2nd - loop thru selected range
                    let selectedRangeLow = 0;
                    if (this.currentPage - halfPageRange > 0) {
                        selectedRangeLow = this.currentPage - 1 - halfPageRange;
                    }
                    let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
                    if (selectedRangeHigh >= this.pages) {
                        selectedRangeHigh = this.pages - 1;
                        selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
                    }

                    // Check if there is breakView in the left of selected range
                    if (selectedRangeLow > this.marginPages) {
                        setBreakView(selectedRangeLow - 1)
                    }

                    for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pages - 1; i++) {
                        if(i === 0 || i === this.pages-1)
                            continue;

                        setPageItem(i);
                    }
                    // Check if there is breakView in the right of selected range
                    if (selectedRangeHigh + 1 < this.pages - this.marginPages) {
                        setBreakView(selectedRangeHigh + 1)
                    }
                    // 3rd - loop thru high end of margin pages
                    for (let i = this.pages; i >= this.pages - this.marginPages; i--) {
                        if(i === this.pages)
                            continue;
                        setPageItem(i);
                    }
                }
                return numbers;
            }
        },
        methods: {
            localClick: function (e, page) {
                e.preventDefault();
                this.$emit('onPageChanged', page);
            },
            link: function (page) {

                // есть сортировка
                if(this.currentSort !== undefined)
                {
                    return page === 1 ? { name: this.routerNameFirstPageSort, params: {
                            sort: this.currentSort
                        }} : { name: this.routerNamePageSort, params: {
                        page: page,
                        sort: this.currentSort,
                    }};
                }

                // нет сортировки
                return page === 1 ? { name: this.routerNameFirstPage} : { name: this.routerNamePage, params:{ page: page }};
            }
        }
    }
</script>