import Request from "../plugins/Request";
import hooks from "../hooks";

export default {
    namespaced: true,
    state: {
        user: {},
        notifications: {},
        reports: 0,
        unread: 0,
        token: localStorage.getItem('token') || '',
        status: '',
        loading: false, // use for pre loaders
    },
    mutations: {
        AUTH_REQUEST(state) {
            state.status = 'loading'
        },
        AUTH_SUCCESS(state, token) {
            state.token  = token;
            state.status = 'success';
        },
        AUTH_ERROR(state) {
            state.status = 'error'
        },
        TOKEN(state, token) {
            localStorage.setItem('token', token);
            Request.defaults.headers.common['Authorization'] = 'Bearer ' + token;

            state.token = token
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        LOGOUT(state) {
            state.token  = '';
            state.status = '';

            state.user          = {};
            state.notifications = {};
            state.unread        = {};
        },
        USER(state, user) {
            state.user = user;
        },
        NOTIFICATIONS(state, notifications) {
            state.notifications = notifications;
        },
        UNREAD(state, unread) {
            state.unread = unread;
        },
        REPORTS(state, reports) {
            state.reports = reports;
        },
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('AUTH_REQUEST');

                Request.post('/account/login', user)
                    .then(resp => {
                        const token = resp.data.token;
                        localStorage.setItem('token', token);
                        Request.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                        commit('AUTH_SUCCESS', token);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('AUTH_ERROR');
                        localStorage.removeItem('token');
                        reject(err);
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve, reject) => {
                const logoutAction = ()=>{
                    commit('LOGOUT');
                    localStorage.removeItem('token');
                    delete Request.defaults.headers.common['Authorization'];
                    hooks.OnAfterLogout();
                };
                Request.post('/account/logout')
                    .then(resp => {
                        logoutAction();
                        resolve(resp);
                    })
                    .catch(err => {
                        logoutAction();
                        reject(err);
                    });
            })
        },
        me({commit}) {
            return new Promise((resolve, reject) => {
                Request.get('/account/me')
                    .then(resp => {
                        commit('USER', resp.data.user);
                        commit('NOTIFICATIONS', resp.data.notifications);
                        commit('REPORTS', resp.data.reports);
                        commit('UNREAD', resp.data.unread);

                        resolve(resp);
                    })
                    .catch(err => {
                        commit('USER', {});
                        commit('NOTIFICATIONS', {});
                        commit('REPORTS', 0);
                        commit('UNREAD', 0);

                        reject(err);
                    });
            })
        },
        update({commit}, user) {
            // return promise
            return new Promise((resolve, reject) => {

                // check params
                if(!user.hasOwnProperty('id'))
                {
                    // show error
                    const errorMsg = 'Невозможно обновить профиль без ID.';
                    showError(`Store account->update: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.post('/account/edit/', user)
                    .then(resp => {

                        // check data
                        if(!resp.data.hasOwnProperty('user') || !resp.data.user.hasOwnProperty('id'))
                        {
                            // show error
                            const errorMsg = 'Не удалось обновить профиль.';
                            showError(`Store account->update: ${errorMsg}`);
                            return reject(errorMsg);
                        }

                        // set state
                        commit('USER', resp.data.user);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);

                    })
                    .catch(err => {

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        if (err.status !== 422)
                            showError("Store account->update: server error");

                        reject(err);
                    })
            })
        },

    },
    getters: {
        isLoggedIn: state => !!state.token,
        isSuperAdmin: state => typeof state.user === "object" && state.user.hasOwnProperty('role') && state.user.role === 1,
        isAdmin: state => typeof state.user === "object" && state.user.hasOwnProperty('role') && state.user.role === 4,
        isUser: state => typeof state.user === "object" && state.user.hasOwnProperty('role') && state.user.role === 8,
        authStatus: state => state.status,
    }
};
