import Request from "../plugins/Request";
import FileDownload from "../plugins/FileDownload";
import qs from "qs";

export default {
    namespaced: true,
    state: {
        rights: {},
        list: [],
        count: 0,
        pages: 0,
        current: 0,
        loading: false, // use for pre loaders
    },
    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },
        SET_COUNT(state, count) {
            state.count = count;
        },
        SET_PAGES(state, pages) {
            state.pages = pages;
        },
        SET_CURRENT(state, current) {
            state.current = current;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_RIGHTS(state, rights) {
            state.rights = rights;
        },
    },
    actions: {
        list({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get('/reports', {
                    params: {
                        limit:    payload.limit || 20,
                        offset:   payload.offset || 0,
                        order_by: payload.orderBy || 'id DESC',
                        filter:   payload.filter || {},
                        query:    payload.query || {},
                    },
                    paramsSerializer: function(params) {
                        return qs.stringify(params, {arrayFormat: 'repeat'});
                    },
                })
                    .then(resp => {

                        // set state
                        commit('SET_LIST', resp.data.list);
                        commit('SET_COUNT', resp.data.count);
                        commit('SET_CURRENT', resp.data.current);
                        commit('SET_PAGES', resp.data.pages);
                        commit('SET_RIGHTS', resp.data.rights);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state
                        commit('SET_LIST', []);
                        commit('SET_COUNT', 0);
                        commit('SET_CURRENT', 0);
                        commit('SET_PAGES', 0);
                        commit('SET_RIGHTS', {});

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store reports->list: server error");

                        reject(err);
                    })
            })
        },
        create({commit}, report) {
            // return promise
            return new Promise((resolve, reject) => {

                // check params
                if(!report.hasOwnProperty('campaign_id'))
                {
                    // show error
                    const errorMsg = 'Нельзя создать отчёт без ID адресной программы.';
                    showError(`Store reports->create: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // check params
                if(!report.hasOwnProperty('date_from') || !report.hasOwnProperty('date_to'))
                {
                    // show error
                    const errorMsg = 'Нельзя создать отчёт без дат.';
                    showError(`Store reports->create: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // request
                Request.post('/reports', report)
                    .then(resp => {

                        // check data
                        if(!resp.data.hasOwnProperty('report') || !resp.data.report.hasOwnProperty('id'))
                        {
                            // show error
                            const errorMsg = 'Не удалось создать отчёт.';
                            showError(`Store reports->create: ${errorMsg}`);
                            return reject(errorMsg);
                        }

                        resolve(resp);

                    })
                    .catch(err => {
                        // show error
                        if (err.response.status !== 422)
                            showError("Store reports->create: server error");

                        reject(err);
                    })
            })
        },
        download({commit}, report) {
            // return promise
            return new Promise((resolve, reject) => {

                // check params
                if(!report.hasOwnProperty('reportId'))
                {
                    // show error
                    const errorMsg = 'Нельзя скачать отчёт без ID отчёта.';
                    showError(`Store reports->download: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // request
                Request.get(`/reports/${report.reportId}/download`, {responseType: 'blob'})
                    .then(resp => {
                        const contentDisposition = resp.headers['content-disposition'];
                        let fileName = 'unknown';
                        if (contentDisposition) {
                            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                            if (fileNameMatch.length === 2)
                                fileName = fileNameMatch[1];
                        }
                        FileDownload(resp.data, fileName, resp.headers['content-type']);

                        resolve(resp);

                    })
                    .catch(err => {
                        let reader = new FileReader();
                        reader.addEventListener("loadend", function() {
                            const error = JSON.parse(reader.result.toString());
                            if(error.message)
                            {
                                const errorMessage = (error.message) ? error.message : "Store reports->download";
                                showError(errorMessage);
                                reject(err);
                            }
                        });

                        if(err.response)
                            reader.readAsText(err.response.data);
                        else
                            reject(err);
                    })
            })
        },
    },
    getters: {
    }
};
