import Request from "../plugins/Request";
import qs from "qs";

export default {
    namespaced: true,
    state: {
        citySuppliers: [],
        citySurfaceTypes: [],
        citySurfaceDimensions: [],

        list: [],
        count: 0,
        pages: 0,
        current: 0,

        listAll: [],
        countAll: 0,
        pagesAll: 0,
        currentAll: 0,

        loading: false, // use for pre loaders
    },
    mutations: {
        SET_CITY_SUPPLIERS(state, list) {
            state.citySuppliers = list;
        },
        SET_CITY_SURFACE_TYPES(state, surfaceTypes) {
            state.citySurfaceTypes = surfaceTypes;
        },
        SET_CITY_SURFACE_DIMENSIONS(state, surfaceDimensions) {
            state.citySurfaceDimensions = surfaceDimensions;
        },
        SET_LIST(state, list) {
            state.list = list;
        },
        SET_COUNT(state, count) {
            state.count = count;
        },
        SET_PAGES(state, pages) {
            state.pages = pages;
        },
        SET_CURRENT(state, current) {
            state.current = current;
        },
        SET_LIST_ALL(state, list) {
            state.listAll = list;
        },
        SET_COUNT_ALL(state, count) {
            state.countAll = count;
        },
        SET_PAGES_ALL(state, pages) {
            state.pagesAll = pages;
        },
        SET_CURRENT_ALL(state, current) {
            state.currentAll = current;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        CLEAR_STATE(state) {
            state.list = [];
            state.count = 0;
            state.pages = 0;
            state.current = 0;

            state.listAll = [];
            state.countAll = 0;
            state.pagesAll = 0;
            state.currentAll = 0;
        },
    },
    actions: {
        list({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('campaignId') || parseInt(payload.campaignId) === 0)
                {
                    // show error
                    const errorMsg = 'Невозможно получить список поверхностей без ID адресной программы.';
                    showError(`Store campaignSurface->list: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get(`/campaigns/${payload.campaignId}/surfaces`, {
                    params: {
                        limit:    payload.limit || 20,
                        offset:   payload.offset || 0,
                        order_by: payload.orderBy || 'id DESC',
                        filter:   payload.filter || {},
                        query:    payload.query || {},
                    },
                    paramsSerializer: function(params) {
                        return qs.stringify(params, {arrayFormat: 'repeat'});
                    },
                })
                    .then(resp => {

                        // set state
                        commit('SET_LIST', resp.data.list);
                        commit('SET_COUNT', resp.data.count);
                        commit('SET_CURRENT', resp.data.current);
                        commit('SET_PAGES', resp.data.pages);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state
                        commit('SET_LIST', []);
                        commit('SET_COUNT', 0);
                        commit('SET_CURRENT', 0);
                        commit('SET_PAGES', 0);

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store campaignSurface->list: server error");

                        reject(err);
                    })
            })
        },
        citySuppliers({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('campaignId') || parseInt(payload.campaignId) === 0)
                {
                    // show error
                    const errorMsg = 'Невозможно получить список поставщиков без ID адресной программы.';
                    showError(`Store campaignSurface->suppliers: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get(`/campaigns/${payload.campaignId}/suppliers`)
                    .then(resp => {

                        // set state
                        commit('SET_CITY_SUPPLIERS', resp.data);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state
                        commit('SET_CITY_SUPPLIERS', []);

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store campaignSurface->suppliers: server error");

                        reject(err);
                    })
            })
        },
        citySurfaceDimensions({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('campaignId') || parseInt(payload.campaignId) === 0)
                {
                    // show error
                    const errorMsg = 'Невозможно получить список размеров без ID адресной программы.';
                    showError(`Store campaignSurface->citySurfaceDimensions: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get(`/campaigns/${payload.campaignId}/surface-dimensions`)
                    .then(resp => {

                        // set state
                        commit('SET_CITY_SURFACE_DIMENSIONS', resp.data);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state
                        commit('SET_CITY_SURFACE_DIMENSIONS', []);

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store campaignSurface->citySurfaceDimensions: server error");

                        reject(err);
                    })
            })
        },
        citySurfaceTypes({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('campaignId') || parseInt(payload.campaignId) === 0)
                {
                    // show error
                    const errorMsg = 'Невозможно получить список форматов без ID адресной программы.';
                    showError(`Store campaignSurface->citySurfaceTypes: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get(`/campaigns/${payload.campaignId}/surface-types`)
                    .then(resp => {

                        // set state
                        commit('SET_CITY_SURFACE_TYPES', resp.data);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state
                        commit('SET_CITY_SURFACE_TYPES', []);

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store campaignSurface->citySurfaceTypes: server error");

                        reject(err);
                    })
            })
        },
        listAll({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('campaignId') || parseInt(payload.campaignId) === 0)
                {
                    // show error
                    const errorMsg = 'Невозможно получить список поверхностей без ID адресной программы.';
                    showError(`Store campaignSurface->listAll: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get(`/campaigns/${payload.campaignId}/surfaces-all`, {
                    params: {
                        limit:        payload.limit || 20,
                        offset:       payload.offset || 0,
                        order_by:     payload.orderBy || 'id DESC',
                        filter:       payload.filter || {},
                        query:        payload.query || {},
                        not_included: payload.not_included || 0,
                    },
                    paramsSerializer: function(params) {
                        return qs.stringify(params, {arrayFormat: 'repeat'});
                    },
                })
                    .then(resp => {

                        // set state
                        commit('SET_LIST_ALL', resp.data.list);
                        commit('SET_COUNT_ALL', resp.data.count);
                        commit('SET_CURRENT_ALL', resp.data.current);
                        commit('SET_PAGES_ALL', resp.data.pages);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state
                        commit('SET_LIST_ALL', []);
                        commit('SET_COUNT_ALL', 0);
                        commit('SET_CURRENT_ALL', 0);
                        commit('SET_PAGES_ALL', 0);

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store campaignSurface->listAll: server error");

                        reject(err);
                    })
            })
        },
        uploadCsv({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                // check params
                if(!payload.hasOwnProperty('campaignId'))
                {
                    // show error
                    const errorMsg = 'Невозможно загрузить файл без ID адресной программы.';
                    showError(`Store campaignSurface->uploadCsv: ${errorMsg}`);
                    return reject(errorMsg);
                }

                if(!payload.hasOwnProperty('formData'))
                {
                    // show error
                    const errorMsg = 'Не передан файл.';
                    showError(`Store campaignSurface->uploadCsv: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                Request.post(`/campaigns/${payload.campaignId}/surfaces/import`, payload.formData, { headers: {'Content-Type': 'multipart/form-data' }})
                    .then((resp) => {
                        // hide preloader
                        commit('SET_LOADING', false);

                        resolve(resp);

                    }).catch((errorMsg) => {
                    // show error message
                    showError("При загрузке поверхностей произошла ошибка.");

                    // hide preloader
                    commit('SET_LOADING', false);
                    reject(errorMsg);
                });
            });
        },
        delete({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('campaignId') || parseInt(payload.campaignId) === 0 ||
                    !payload.hasOwnProperty('surfaceId') || parseInt(payload.surfaceId) === 0)
                {
                    // show error
                    const errorMsg = 'Невозможно удалить поверхность без ID адресной программы и ID поверхности.';
                    showError(`Store campaignSurface->delete: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.delete(`/campaigns/${payload.campaignId}/surfaces/${payload.surfaceId}`)
                    .then(resp => {
                        // stop pre loader
                        commit('SET_LOADING', false);

                        resolve(resp);
                    })
                    .catch(err => {
                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store campaignSurface->delete: server error");

                        reject(err);
                    })
            })
        },
        addSurfaces({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('campaignId') || parseInt(payload.campaignId) === 0 ||
                    !payload.hasOwnProperty('surfaces') || payload.surfaces.length === 0)
                {
                    // show error
                    const errorMsg = 'Невозможно добавить поверхности без ID адресной программы и списка ID поверхностей.';
                    showError(`Store campaignSurface->addSurfaces: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.post(`/campaigns/${payload.campaignId}/surfaces/add`, {
                    surfaces: payload.surfaces.join(',')
                })
                    .then(resp => {
                        // stop pre loader
                        commit('SET_LOADING', false);

                        resolve(resp);
                    })
                    .catch(err => {
                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store campaignSurface->addSurfaces: server error");

                        reject(err);
                    })
            })
        },
        deleteSurfaces({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('campaignId') || parseInt(payload.campaignId) === 0 ||
                    !payload.hasOwnProperty('surfaces') || payload.surfaces.length === 0)
                {
                    // show error
                    const errorMsg = 'Невозможно удалить поверхности без ID адресной программы и списка ID поверхностей.';
                    showError(`Store campaignSurface->deleteSurfaces: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.post(`/campaigns/${payload.campaignId}/surfaces/remove`, {
                    surfaces: payload.surfaces.join(',')
                })
                    .then(resp => {
                        // stop pre loader
                        commit('SET_LOADING', false);

                        resolve(resp);
                    })
                    .catch(err => {
                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store campaignSurface->deleteSurfaces: server error");

                        reject(err);
                    })
            })
        },
        mapSurfaceAdded({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                if(!payload.hasOwnProperty('campaignId') || parseInt(payload.campaignId) === 0)
                {
                    // show error
                    const errorMsg = 'Невозможно получить поверхности без ID адресной программы.';
                    showError(`Store campaignSurface->mapSurfaceAdded: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get(`/campaigns/${payload.campaignId}/map-surface-added`, {
                    params: {
                        filter:   payload.filter || {},
                        query:    payload.query || {},
                    },
                    paramsSerializer: function(params) {
                        return qs.stringify(params, {arrayFormat: 'repeat'});
                    },
                })
                .then(resp => {
                    // stop pre loader
                    commit('SET_LOADING', false);

                    resolve(resp);
                })
                .catch(err => {
                    // stop pre loader
                    commit('SET_LOADING', false);

                    // show error
                    showError("Store campaignSurface->mapSurfaceAdded: server error");

                    reject(err);
                })
            })
        },
    },
    getters: {
    }
};
