import Vue from 'vue'
import Config from './vue.config'
import './utils'


Vue.prototype.window_portal = window;


/**
 * Permission directive
 */
Vue.prototype.$can = function(object, access) {
    return access && object.hasOwnProperty('rights') && typeof object.rights[access] !== "undefined";
};

/**
 * META
 */
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});

/**
 * Dropdown
 */
import Dropdown from './components/dropdown/DropdownComponent';
Vue.component('Dropdown', Dropdown);

/**
 * Breadcrumbs
 */
import Breadcrumbs from './components/breadcrumbs/BreadcrumbsComponent';
Vue.component('Breadcrumbs', Breadcrumbs);

/**
 * WR MODAL
 */
import WrModalComponent from './components/modal/WrModalComponent'
Vue.component('WrModalComponent', WrModalComponent);

/**
 * Notification
 */
import Notifications from 'vue-notification';
Vue.use(Notifications);

/**
 * Table
 */
import VueGoodTablePlugin from 'vue-good-table';
Vue.use(VueGoodTablePlugin);

/**
 * Datepicker
 */
import DatePicker from 'vue2-datepicker';
Vue.use(DatePicker);

/**
 * Tooltip
 */
import VTooltip from 'v-tooltip';
Vue.use(VTooltip);
VTooltip.options.popover.defaultWrapperClass = 'popoverWrapperClass';

/**
 * Preloader
 */
import Preloader from './components/preloader/PreloaderComponent';
Vue.component('Preloader', Preloader);

/**
 * CustomPaginationComponent
 */
import CustomPagination from './components/custom_pagination/CustomPaginationComponent';
Vue.component('CustomPagination', CustomPagination);

/**
 * Lodash
 */
import cloneDeep from 'lodash/cloneDeep';
Vue.prototype.$cloneDeep = cloneDeep;
window.cloneDeep = cloneDeep;

import isEmpty from 'lodash/isEmpty';
Vue.prototype.$isEmpty = isEmpty;
window.isEmpty = isEmpty;

import isObject from 'lodash/isObject';
Vue.prototype.$isObject = isObject;
window.isObject = isObject;

/**
 * Validation
 */
import VeeValidate, { Validator } from 'vee-validate';
import messagesRu from 'vee-validate/dist/locale/ru';
Vue.use(VeeValidate, {
    errorBagName: 'validationErrors',
    fieldsBagName: 'validationFields',
});
Validator.localize('ru', messagesRu);

/**
 * Global show error
 *
 * @param msg
 * @param title
 * @param duration
 * @param consoleShow
 */
window.showError = (msg, title = 'Ошибка', duration = 1000000000, consoleShow = true) => {
    Vue.notify({
        group: 'main',
        type: 'error',
        duration: duration,
        title: title,
        text: msg
    });

    if(consoleShow || Config.isDev)
        console.error(title, msg);
};

/**
 * Global show warning
 *
 * @param msg
 * @param title
 */
window.showWarn = (msg, title = 'Предупреждение') => {
    Vue.notify({
        group: 'main',
        type: 'warn',
        title: title,
        text: msg
    });

    if(Config.isDev)
        console.warn(title, msg);
};

/**
 * Global show success
 *
 * @param msg
 * @param title
 */
window.showSuccess = (msg, title = 'Успешно') => {
    Vue.notify({
        group: 'main',
        type: 'success',
        title: title,
        text: msg
    });

    if(Config.isDev)
        console.log(title, msg);
};