import Vue from "vue";
import store from './store'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

// CAMPAIGN PAGES
const CampaignListPage = () => import(/* webpackChunkName: "campaign-list" */ './pages/CampaignList/CampaignListPage.vue');
const CampaignPage = () => import(/* webpackChunkName: "campaign" */ './pages/Campaign/CampaignPage.vue');

// SURFACE PAGES
const SurfaceListPage = () => import(/* webpackChunkName: "surface-list" */ './pages/SurfaceList/SurfaceListPage.vue');

// REPORT PAGES
const ReportListPage = () => import(/* webpackChunkName: "report-list" */ './pages/ReportList/ReportListPage.vue');

// USER PAGES
const UserListPage = () => import(/* webpackChunkName: "user-list" */ './pages/UserList/UserListPage.vue');
const UserPage = () => import(/* webpackChunkName: "user" */ './pages/User/UserPage.vue');

// PROFILE PAGE
const ProfilePage = () => import(/* webpackChunkName: "profile" */ './pages/Profile/ProfilePage.vue');

// LOGIN PAGE
const LoginPage = () => import(/* webpackChunkName: "login" */ './pages/Login/LoginPage.vue');

// TECHNICAL PAGES
const TestPage = () => import(/* webpackChunkName: "test" */ './pages/TestPage.vue');
const ComponentsPage = () => import(/* webpackChunkName: "components" */ './pages/ComponentsPage.vue');
const ForbiddenPage = () => import(/* webpackChunkName: "403" */ './pages/ForbiddenPage.vue');
const NotFoundPage = () => import(/* webpackChunkName: "404" */ './pages/NotFoundPage.vue');

let routes = [
    // CAMPAIGN LIST
    { path: '/', component: CampaignListPage, name: 'campaigns', meta: {requiresAuth:true}},
    { path: '/page/:page', component: CampaignListPage, name: 'campaigns-page', meta: {requiresAuth:true}},
    { path: '/sort/:sort', component: CampaignListPage, name: 'campaigns-sort', meta: {requiresAuth:true}},
    { path: '/page/:page/sort/:sort', component: CampaignListPage, name: 'campaigns-page-sort', meta: {requiresAuth:true}},

    // CAMPAIGN EDIT
    { path: '/campaigns/create', component: CampaignPage, name: 'campaigns-create', props: { isNew: true }, meta: {requiresAuth:true}},
    { path: '/campaigns/:campaignId', component: CampaignPage, name: 'campaigns-edit', props: { isNew: false }, meta: {requiresAuth:true}},

    { path: '/profile', component: ProfilePage, name: 'profile', meta: {requiresAuth:true}},

    // SURFACES LIST
    { path: '/surfaces', component: SurfaceListPage, name: 'surfaces', meta: {requiresAuth:true}},
    { path: '/surfaces/page/:page', component: SurfaceListPage, name: 'surfaces-page', meta: {requiresAuth:true}},
    { path: '/surfaces/sort/:sort', component: SurfaceListPage, name: 'surfaces-sort', meta: {requiresAuth:true}},
    { path: '/surfaces/page/:page/sort/:sort', component: SurfaceListPage, name: 'surfaces-page-sort', meta: {requiresAuth:true}},

    // SURFACE EDIT
    // { path: '/surfaces/create', component: TestPage, name: 'surfaces-create', props: { isNew: true }},
    // { path: '/surfaces/:surfaceId', component: TestPage, name: 'surfaces-edit', props: { isNew: false }},

    // REPORT LIST
    { path: '/reports', component: ReportListPage, name: 'reports', meta: {requiresAuth:true}},
    { path: '/reports/page/:page', component: ReportListPage, name: 'reports-page', meta: {requiresAuth:true}},
    { path: '/reports/sort/:sort', component: ReportListPage, name: 'reports-sort', meta: {requiresAuth:true}},
    { path: '/reports/page/:page/sort/:sort', component: ReportListPage, name: 'reports-page-sort', meta: {requiresAuth:true}},

    // USER LIST
    { path: '/users', component: UserListPage, name: 'users', meta: {requiresAuth:true}},
    { path: '/users/page/:page', component: UserListPage, name: 'users-page', meta: {requiresAuth:true}},
    { path: '/users/sort/:sort', component: UserListPage, name: 'users-sort', meta: {requiresAuth:true}},
    { path: '/users/page/:page/sort/:sort', component: UserListPage, name: 'users-page-sort', meta: {requiresAuth:true}},

    // USER EDIT
    { path: '/users/create', component: UserPage, name: 'users-create', props: { isNew: true }, meta: {requiresAuth:true}},
    { path: '/users/:userId', component: UserPage, name: 'users-edit', props: { isNew: false }, meta: {requiresAuth:true}},

    { path: '/login', component: LoginPage, name: 'login', meta: {layout: 'Login'}},

    // develop
    { path: '/components', component: ComponentsPage, name: 'components', meta: {requiresAuth:true}},
    { path: '/test', component: TestPage, name: 'test', meta: {requiresAuth:true}},

    // ERROR 403
    {
        path: '/403',
        name: '403',
        component: ForbiddenPage,
    },
    // ERROR 404
    {
        path: '/404',
        name: '404',
        component: NotFoundPage,
    },
    {
        path: '*',
        redirect: '/'
    }
];

let router = new VueRouter({
    mode: 'history',
    root: '/',
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['account/isLoggedIn']) {
            next();
            return;
        }
        next({name:'login'});
    } else {
        next();
    }
});

export default router;