import Request from "../plugins/Request";
// import qs from "qs";

export default {
    namespaced: true,
    state: {
        roles: {},
        statuses: {},
        types: {},
        suppliers: [],
        surfaceTypes: [],
        surfaceDimensions: [],
        loading: false, // use for pre loaders
    },
    mutations: {
        SET_ROLES(state, roles) {
            state.roles = roles;
        },
        SET_STATUSES(state, statuses) {
            state.statuses = statuses;
        },
        SET_TYPES(state, types) {
            state.types = types;
        },
        SET_SUPPLIERS(state, suppliers) {
            state.suppliers = suppliers;
        },
        SET_SURFACE_TYPES(state, surfaceTypes) {
            state.surfaceTypes = surfaceTypes;
        },
        SET_SURFACE_DIMENSIONS(state, surfaceDimensions) {
            state.surfaceDimensions = surfaceDimensions;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
    },
    actions: {
        index({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get('/dictionary')
                    .then(resp => {

                        // set state
                        commit('SET_ROLES', resp.data.roles);
                        commit('SET_STATUSES', resp.data.statuses);
                        commit('SET_TYPES', resp.data.types);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state
                        commit('SET_ROLES', {});
                        commit('SET_STATUSES', {});
                        commit('SET_TYPES', {});

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        // showError("Store dictionary->index: server error");

                        reject(err);
                    })
            })
        },
        suppliers({commit}) {
            // return promise
            return new Promise((resolve, reject) => {

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get('/dictionary/suppliers')
                    .then(resp => {

                        // set state
                        commit('SET_SUPPLIERS', resp.data);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state
                        commit('SET_SUPPLIERS', []);

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store dictionary->suppliers: server error");

                        reject(err);
                    })
            })
        },
        surfaceDimensions({commit}) {
            // return promise
            return new Promise((resolve, reject) => {

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get('/dictionary/surface-dimensions')
                    .then(resp => {

                        // set state
                        commit('SET_SURFACE_DIMENSIONS', resp.data);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state
                        commit('SET_SURFACE_DIMENSIONS', []);

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store dictionary->surfaceDimensions: server error");

                        reject(err);
                    })
            })
        },
        surfaceTypes({commit}) {
            // return promise
            return new Promise((resolve, reject) => {

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get('/dictionary/surface-types')
                    .then(resp => {

                        // set state
                        commit('SET_SURFACE_TYPES', resp.data);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state
                        commit('SET_SURFACE_TYPES', []);

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store dictionary->surfaceTypes: server error");

                        reject(err);
                    })
            })
        },
    },
    getters: {
    }
};
