<template>
    <div id="app">
        <component :is="layout">
            <router-view></router-view>
        </component>
        <notifications group="main"></notifications>
    </div>
</template>
<script>
    import LoginLayout from './layouts/LoginLayout'
    import MainLayout from './layouts/MainLayout'
    import Config from './vue.config'

    export default {
        name: 'app',
        components: {
            MainLayout,
            LoginLayout
        },
        computed: {
            layout() {
                return (typeof this.$route.meta.layout !== 'undefined')
                    ? `${this.$route.meta.layout}Layout`: 'MainLayout';
            },
        },
        metaInfo: () => ({
            title: Config.defaultTitle,
            titleTemplate: Config.defaultTitleTemplate
        })
    }
</script>

<style lang="scss">
    @import "app";
</style>