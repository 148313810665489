<template>
    <nav class="nav-bar">
        <div class="wrapper">
            <div>
                <a href="#" class="nav-bar__burger" @click.prevent="toggleBurger" :class="(isMenuOpen) ? 'nav-bar__burger--open': ''"><div></div></a>
                <router-link :to="{ name: 'campaigns' }" class="nav-bar__logo">STAT</router-link>
            </div>
            <ul class="nav-bar__items" :class="(isMenuOpen) ? 'nav-bar__items--open': ''">
                <li class="nav-bar__item" v-for="item in itemList"><router-link :to="item.url" :exact="item.url.name === 'campaigns'" v-if="!item.hasOwnProperty('hidden') || (item.hasOwnProperty('hidden') && !item.hidden)"><div @click="closeMenu">{{ item.title }}<span class="badge--primary badge" v-if="item.url.name === 'reports' && reports > 0">{{ reports }}</span></div></router-link></li>
                <li class="nav-bar__item visible-block-sm visible-block-xs hidden-block-md hidden-lg"><router-link :to="{name:'profile'}"><div @click="closeMenu">Профиль</div></router-link></li>
                <li class="nav-bar__item visible-block-sm visible-block-xs hidden-block-md hidden-lg"><a href="#" class="logout" @click.prevent="logout"><div>Выйти</div></a></li>
            </ul>
            <ul class="nav-bar__items">
                <li class="nav-bar__item hidden-sm hidden-xs hidden-md visible-lg">
                    {{name}}
                </li>
                <li class="nav-bar__item hidden-sm hidden-xs visible-md visible-lg"><router-link :to="{name:'profile'}" class="logout" title="Профиль"><div><i class="fas fa-user"></i></div></router-link></li>
                <li class="nav-bar__item hidden-sm hidden-xs visible-md visible-lg"><a href="#" class="logout" @click.prevent="logout" title="Выйти"><div><i class="fas fa-sign-out-alt"></i></div></a></li>
            </ul>
        </div>
    </nav>
</template>

<script>
    export default {
        name: "NavigationComponent",
        props:{
            items: {
                type: Array,
                required: false,
                default: []
            },
        },
        data() {
            return {
                isMenuOpen: false,
            }
        },
        computed: {
            itemList() {
                return this.items;
            },
            reports() {
                return this.$store.state.account.reports
            },
            name() {
                return this.$store.state.account.user ? this.$store.state.account.user.agency_title ? `${this.$store.state.account.user.email} (${this.$store.state.account.user.agency_title})` : this.$store.state.account.user.email : '';
            },
        },
        methods: {
            closeMenu() {
                this.isMenuOpen = false;
            },
            toggleBurger() {
                this.isMenuOpen = !this.isMenuOpen;
            },
            logout() {
                if(!confirm("Вы действительно хотите выйти?"))
                    return;

                this.$store.dispatch('account/logout')
                    .then(() => {
                        this.$router.push({name:"login"})
                    }).catch(()=> {
                        console.error("Failed logout.");
                        this.$router.push({name:"login"})
                    });
                }
        }
    }
</script>
