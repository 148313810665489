import Request from "../plugins/Request";
import qs from "qs";

export default {
    namespaced: true,
    state: {
        rights: {},
        editUser: {},
        list: [],
        count: 0,
        pages: 0,
        current: 0,
        loading: false, // use for pre loaders
    },
    mutations: {
        SET_EDIT_USER(state, user) {
            state.editUser = user;
        },
        SET_LIST(state, list) {
            state.list = list;
        },
        SET_COUNT(state, count) {
            state.count = count;
        },
        SET_PAGES(state, pages) {
            state.pages = pages;
        },
        SET_CURRENT(state, current) {
            state.current = current;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_RIGHTS(state, rights) {
            state.rights = rights;
        },
    },
    actions: {
        list({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get('/users', {
                        params: {
                            limit:    payload.limit || 20,
                            offset:   payload.offset || 0,
                            order_by: payload.orderBy || 'id DESC',
                            filter:   payload.filter || {},
                            query:    payload.query || {},
                        },
                        paramsSerializer: function(params) {
                            return qs.stringify(params, {arrayFormat: 'repeat'})
                        },
                    })
                    .then(resp => {

                        // set state
                        commit('SET_LIST', resp.data.list);
                        commit('SET_COUNT', resp.data.count);
                        commit('SET_CURRENT', resp.data.current);
                        commit('SET_PAGES', resp.data.pages);
                        commit('SET_RIGHTS', resp.data.rights);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // reset state
                        commit('SET_LIST', []);
                        commit('SET_COUNT', 0);
                        commit('SET_CURRENT', 0);
                        commit('SET_PAGES', 0);
                        commit('SET_RIGHTS', {});

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store user->list: server error");

                        reject(err);
                    })
            })
        },
        view({commit}, payload) {
            // return promise
            return new Promise((resolve, reject) => {

                // check params
                if(!payload.hasOwnProperty('userId'))
                {
                    // show error
                    const errorMsg = 'Не передан userId.';
                    showError(`Store user->view: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.get('/users/' + payload.userId)
                    .then(resp => {

                        // check data
                        if(!resp.data.hasOwnProperty('id'))
                        {
                            // show error
                            const errorMsg = 'Пользователь с таким ID не найден.';
                            showError(`Store user->view: ${errorMsg}`);
                            return reject(errorMsg);
                        }

                        // set state
                        commit('SET_EDIT_USER', resp.data);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);

                    })
                    .catch(err => {
                        // reset state
                        commit('SET_EDIT_USER', {});

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        showError("Store user->view: server error");

                        reject(err);
                    })
            })
        },
        create({commit}, user) {
            // return promise
            return new Promise((resolve, reject) => {

                // check params
                if(user.hasOwnProperty('id'))
                {
                    // show error
                    const errorMsg = 'Нельзя создать пользователя с ID.';
                    showError(`Store user->create: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.post('/users', user)
                    .then(resp => {

                        // check data
                        if(!resp.data.hasOwnProperty('user') || !resp.data.user.hasOwnProperty('id'))
                        {
                            // show error
                            const errorMsg = 'Не удалось создать пользователя.';
                            showError(`Store user->create: ${errorMsg}`);
                            return reject(errorMsg);
                        }

                        // set state
                        commit('SET_EDIT_USER', resp.data.user);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);

                    })
                    .catch(err => {
                        // reset state
                        commit('SET_EDIT_USER', {});

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        if (err.response.status !== 422)
                            showError("Store user->create: server error");

                        reject(err);
                    })
            })
        },
        update({commit}, user) {
            // return promise
            return new Promise((resolve, reject) => {

                // check params
                if(!user.hasOwnProperty('id'))
                {
                    // show error
                    const errorMsg = 'Невозможно обновить пользователя без ID.';
                    showError(`Store user->update: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.put('/users/' + user.id, user)
                    .then(resp => {

                        // check data
                        if(!resp.data.hasOwnProperty('user') || !resp.data.user.hasOwnProperty('id'))
                        {
                            // show error
                            const errorMsg = 'Не удалось обновить пользователя.';
                            showError(`Store user->update: ${errorMsg}`);
                            return reject(errorMsg);
                        }

                        // set state
                        commit('SET_EDIT_USER', resp.data.user);

                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);

                    })
                    .catch(err => {
                        // reset state
                        commit('SET_EDIT_USER', {});

                        // stop pre loader
                        commit('SET_LOADING', false);

                        // show error
                        if (err.status !== 422)
                            showError("Store user->update: server error");

                        reject(err);
                    })
            })
        },
        delete: ({commit}, user) => {
            // return promise
            return new Promise((resolve, reject) => {

                // check params
                if(!user.hasOwnProperty('userId'))
                {
                    // show error
                    const errorMsg = 'Невозможно удалить пользователя без ID.';
                    showError(`Store user->delete: ${errorMsg}`);
                    return reject(errorMsg);
                }

                // start pre loader
                commit('SET_LOADING', true);

                // request
                Request.delete('/users/' + user.userId)
                    .then(resp => {
                        // stop pre loader
                        commit('SET_LOADING', false);
                        resolve(resp);
                    })
                    .catch(err => {
                        // stop pre loader
                        commit('SET_LOADING', false);
                        reject(err);
                    });
            });
        },
    },
    getters: {
    }
};
