import Vue from 'vue'
import store from './store'
import router from './routes.js'
import App from './App.vue'
import './global_components'
import './hooks'

Vue.config.productionTip = false;

new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App)
});
