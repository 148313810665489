const isLocal   = /localhost|web.clickhouse.local/.test(document.location.host);
const isDev     = /dh.paltus.ru/.test(document.location.host);
const devApiUrl = isLocal ? 'http://api.clickhouse.local' : 'https://ph.paltus.ru';
const proApiUrl = 'https://pooh.paltus.ru';

module.exports = {
    isDev: isDev || isLocal,
    apiUrl: (isDev || isLocal) ? devApiUrl : proApiUrl,
    defaultTitle: 'Statistic',
    defaultTitleTemplate: '%s | Best service'
};